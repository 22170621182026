<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer ref="criteriaSaveFormRef">
      <b-modal
        id="evaluateCreditClassSaveModal"
        centered
        size="xl"
        title="Xem kết quả phiếu khảo sát/đánh giá"
        no-close-on-backdrop
        hide-footer
        @show="onShow"
        @hide="onHide"
      >
        <div class="font-weight-bold">
          <b-row
            v-if="studentEvaluate.objectGroupEvaluateTeachers"
            class="mx-2 my-2"
          >
            <div class="font-weight-bold">
              <feather-icon
                icon="EditIcon"
                size="22"
              />
              Xếp hạng giảng viên:
            </div>
          </b-row>
          <div v-if="studentEvaluate.objectGroupEvaluateTeachers">
            <b-row
              v-for="(item, index) in studentEvaluate.objectGroupEvaluateTeachers"
              :key="index"
              class="ml-2"
            >
              <b-col
                sm="6"
                md="6"
                lg="4"
                xl="4"
              >
                {{ item.fullName + ' (' + item.lessonNum + ' tiết)' }}
              </b-col>
              <b-col
                sm="6"
                md="6"
                lg="8"
                xl="8"
              >
                <div class="item-rating">
                  <ul class="unstyled-list list-inline">
                    <li
                      v-for="star in 5"
                      :key="star"
                      class="ratings-list-item"
                      :class="{ 'ml-25': star - 1 }"
                    >
                      <feather-icon
                        :id="item.teacherId + '_star_' + star"
                        icon="StarIcon"
                        size="16"
                        class="star-rate"
                      />
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row class="mx-1">
              <b-col>
                Không có giảng viên nào giảng dạy lớp này
              </b-col>
            </b-row>
          </div>
          <b-row
            v-if="studentEvaluate.objectGroupEvaluateCriterias"
            class="mx-2 my-2"
          >
            <div class="font-weight-bold">
              <feather-icon
                icon="EditIcon"
                size="22"
              />
              Kết quả trả lời của các câu hỏi như sau:
            </div>
          </b-row>
          <div v-if="studentEvaluate.objectGroupEvaluateCriterias">
            <div
              v-for="(item, index) in studentEvaluate.objectGroupEvaluateCriterias"
              :key="index"
              class="mx-3"
            >
              <b-row v-if="item.criteriaType === 1">
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group :label-for="item.name">
                    <template v-slot:label>
                      {{ (index + 1) + ". " + item.name }} <span class="text-danger">*</span>
                    </template>
                    <vue-slider
                      v-model="item.point"
                      direction="ltr"
                      :max="item.maxPoint"
                      :min="1"
                      style="width: 50%;"
                      :disabled="true"
                      class="mx-1 my-2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.criteriaType === 2 && item.multipleSelect !== 1">
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group :label-for="item.name">
                    <template v-slot:label>
                      {{ (index + 1) + ". " + item.name }} <span
                        v-if="item.required"
                        class="text-danger"
                      >*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Câu hỏi này"
                      :rules="(item.required ? 'required' : '')"
                    >
                      <b-form-radio-group
                        :id="'skin-radio-group_' + index"
                        v-model="item.evaluationCriteriaOptionId[0]"
                        :name="'skin_' + index"
                        :options="item.evaluationCriteriaOptions"
                        class="custom-control-primary pl-2 radio-group-custom"
                        :state="getElementState(errors)"
                        disabled
                      />
                      <small class="text-danger mx-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.criteriaType === 2 && item.multipleSelect === 1">
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group :label-for="item.name">
                    <template v-slot:label>
                      {{ (index + 1) + ". " + item.name }} <span
                        v-if="item.required"
                        class="text-danger"
                      >*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Câu hỏi này"
                      :rules="(item.required ? 'required' : '')"
                    >
                      <b-form-checkbox-group
                        :id="'skin2-radio-group_' + index"
                        v-model="item.evaluationCriteriaOptionId"
                        class="custom-control-primary pl-2"
                        :options="item.evaluationCriteriaOptions"
                        :state="getElementState(errors)"
                        disabled
                      />
                      <small class="text-danger mx-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.criteriaType === 3">
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group :label-for="item.name">
                    <template v-slot:label>
                      {{ (index + 1) + '. ' + item.name }} <span
                        v-if="item.required"
                        class="text-danger"
                      >*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Câu hỏi này"
                      :rules="(item.required ? 'required' : '') + 'max:1000'"
                    >
                      <b-form-textarea
                        :id="'question_' + index"
                        v-model="item.content"
                        placeholder="Nhập câu trả lời"
                        rows="3"
                        :state="getElementState(errors)"
                        class="mx-1 mt-2"
                        readonly
                      />
                      <small class="text-danger mx-1">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <b-row
            v-if="studentEvaluate.objectGroupEvaluateCriterias"
            class="mx-2 my-2"
          >
            <div class="font-weight-bold">
              <feather-icon
                icon="EditIcon"
                size="22"
              />
              Kết quả trả lời của các câu hỏi đánh giá phòng học như sau:
            </div>
          </b-row>
          <div v-if="studentEvaluate.rooms">
            <div
              v-for="(room, idx) in studentEvaluate.rooms"
              :key="idx"
              class="mx-3"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-2"
                >
                  <feather-icon
                    icon="HomeIcon"
                    size="22"
                  />
                  {{ "Phòng học " + room.roomName + " - " + room.buildingName + "(" + room.roomType + ")" }}
                </b-col>
              </b-row>
              <div
                v-for="(item, index) in room.objectGroupEvaluateRoomCriterias"
                :key="index"
                class="mx-3"
              >
                <b-row v-if="item.criteriaType === 1">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group :label-for="room.id + item.name">
                      <template v-slot:label>
                        {{ (index + 1) + ". " + item.name }} <span class="text-danger">*</span>
                      </template>
                      <vue-slider
                        v-model="item.point"
                        direction="ltr"
                        :max="item.maxPoint"
                        :min="1"
                        style="width: 50%;"
                        :disabled="true"
                        class="mx-1 my-2"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="item.criteriaType === 2 && item.multipleSelect !== 1">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group :label-for="room.id + item.name">
                      <template v-slot:label>
                        {{ (index + 1) + ". " + item.name }} <span
                          v-if="item.required"
                          class="text-danger"
                        >*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Câu hỏi này"
                        :rules="(item.required ? 'required' : '')"
                      >
                        <b-form-radio-group
                          :id="room.id + '_room-radio-group_' + index"
                          v-model="item.evaluationCriteriaOptionId[0]"
                          :options="item.evaluationCriteriaOptions"
                          class="custom-control-primary pl-2 radio-group-custom"
                          :state="getElementState(errors)"
                          disabled
                        />
                        <small class="text-danger mx-1">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="item.criteriaType === 2 && item.multipleSelect === 1">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group :label-for="room.id + item.name">
                      <template v-slot:label>
                        {{ (index + 1) + ". " + item.name }} <span
                          v-if="item.required"
                          class="text-danger"
                        >*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Câu hỏi này"
                        :rules="(item.required ? 'required' : '')"
                      >
                        <b-form-checkbox-group
                          :id="room.id + '_room-checkbox-group_' + index"
                          v-model="item.evaluationCriteriaOptionId"
                          class="custom-control-primary pl-2"
                          :options="item.evaluationCriteriaOptions"
                          :state="getElementState(errors)"
                          disabled
                        />
                        <small class="text-danger mx-1">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="item.criteriaType === 3">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group :label-for="room.id + item.name">
                      <template v-slot:label>
                        {{ (index + 1) + '. ' + item.name }} <span
                          v-if="item.required"
                          class="text-danger"
                        >*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Câu hỏi này"
                        :rules="(item.required ? 'required' : '') + 'max:1000'"
                      >
                        <b-form-textarea
                          :id="room.id + '_room_question_' + index"
                          v-model="item.content"
                          placeholder="Nhập câu trả lời"
                          rows="3"
                          :state="getElementState(errors)"
                          class="mx-1 mt-2"
                          readonly
                        />
                        <small class="text-danger mx-1">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BFormGroup, BModal, BOverlay, BRow, BCol, BFormTextarea, BFormRadioGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import VueSlider from 'vue-slider-component'

export default {
  name: 'CreditClassSave',
  components: {
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormGroup,
    BModal,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    VueSlider,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    setOfCriteriaObjectId: {
      type: Number,
      default: null,
    },
    evaluateObjectGroupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      required,
      isCheckCriterias: true,
      isCheckCriteriaTypeSliders: true,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      studentEvaluate: 'studentEvaluate/studentEvaluate',
    }),
    isCreated() {
      return this.studentEvaluate.id === null
    },
  },
  methods: {
    ...mapMutations({
      setStudentEvaluate: 'studentEvaluate/STUDENT_EVALUATE',
    }),
    ...mapActions({
      createStudentEvaluate: 'studentEvaluate/createStudentEvaluate',
      updateStudentEvaluate: 'studentEvaluate/updateStudentEvaluate',
      getStudentEvaluate: 'studentEvaluate/getStudentEvaluate',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      await this.getStudentEvaluate({
        studentCreditClassId: this.mainData.studentCreditClassId, organizationId: this.user.orgId, setOfCriteriaObjectId: this.setOfCriteriaObjectId, evaluateObjectGroupId: this.evaluateObjectGroupId,
      })
      this.isLoading = false
    },
    onHide() {
      this.setStudentEvaluate({})
      this.$refs.criteriaSaveFormRef.reset()
      if (this.studentEvaluate.id === null) {
        for (let i = 0; i < this.studentEvaluate.objectGroupEvaluateCriterias.length; i++) {
          if (this.studentEvaluate.objectGroupEvaluateCriterias[i].criteriaType === 2) {
            this.studentEvaluate.objectGroupEvaluateCriterias[i].evaluationCriteriaOptionId = []
          }
        }
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .criteriaSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createStudentEvaluate(this.studentEvaluate)
            : await this.updateStudentEvaluate(this.studentEvaluate)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('evaluateCreditClassSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    clickStar(item, index, star) {
      for (let i = 1; i <= 5; i++) {
        const elementId = document.getElementById(`${item.teacherId}_star_${i}`)
        elementId?.classList.remove('fill-current')
        elementId?.classList.remove('text-warning')
        if (i <= star) {
          elementId?.classList.add('fill-current')
          elementId?.classList.add('text-warning')
        } else {
          elementId?.classList.remove('text-muted')
        }
      }
      this.studentEvaluate.objectGroupEvaluateTeachers[index].rate = star
    },
  },
  watch: {
    studentEvaluate(newVal) {
      this.studentEvaluate = newVal

      setTimeout(() => {
        this.studentEvaluate?.objectGroupEvaluateTeachers?.forEach((item, index) => {
          this.clickStar(item, index, item.rate)
        })
      }, 50)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';

.option-index {
  width: 20px;
}

.star-rate {
  cursor: pointer;
}

#evaluateCreditClassSaveModal label {
  font-size: 1rem;
}

#evaluateCreditClassSaveModal .vue-slider-handle-end {
  display: none;
  pointer-events: none;
}

#evaluateCreditClassSaveModal label {
  font-size: 1rem;
}

#evaluateCreditClassSaveModal .vue-slider-handle-end {
  display: none;
  pointer-events: none;
}
</style>
