<template>
  <b-card no-body>
      <div id="chart">
        <vue-apex-charts type="bar" height="200" width="80%" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BDropdownItem, BDropdown,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    criteria: {
      type: Object,
      default: () => {},
    },
    totalEvaluated: {
      type: Number,
      default: () => null,
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false
          }
        },
        colors: ['#3357FF', '#F5C300', '#6A0DAD', '#10b59c', '#b51f74', '#d4242d', '#b88d28', '#7da31d', '#FF5733', '#07b045'], // Các màu tùy chỉnh
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
            height: '100px',
            distributed: true // Add this line to distribute colors
          }
        },
        dataLabels: {
          enabled: true,
          formatter: this.formatterFunction,
          offsetY: 0,
          offsetX: -20,
          style: {
            fontSize: '12px',
            colors: ["#ffffff"], // Màu trắng
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: [{
            formatter: function (y) {
              if(typeof y !== "undefined") {
                return " Đếm " + y + " lựa chọn";
              }
            },
            title: {
              formatter: (seriesName) => '',
            },
          }]
        },
        xaxis: {
          categories: [],
            labels: {
              formatter: function (val) {
                if(val % 1 === 0)
                return val.toFixed(0);
                else
                return '';
              }
            },
        }
      },
    }
  },
  async created() {
    let arrNumberSelected = []
    this.criteria.options.forEach(item => {
      arrNumberSelected.push(item.numberSelected)
      this.chartOptions.xaxis.categories.push(item.name)
    })
    this.series.push({data: arrNumberSelected})
  },
  methods: {
    formatterFunction(val) {
      let percent = (val / this.totalEvaluated) * 100
      if(percent > 0)
        return val + " (" + Math.round(percent) + "%)"
      else
        return ''
    },
  },
}
</script>
